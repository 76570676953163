//KATE
//What is he doing in here?

//PHREAK
//Relax, Burn, he's my guest.

//DADE
//Burn. You're Acid Burn. You booted me out of OTV!

//KATE
//What?

//DADE
//I'm Crash Override.

//KATE 
//You're the moron that's been invading my turf?


//SF CLASS OVERWRITING

.btn-dark {
    color: #fff;
    background-color: #000;
    border-color: #000;
}


.home-emergency {
    .topic-block-title {
        display: block !important;
        visibility: visible;
        border: 0px;
    }
}

.home-emergency {
    .topic-block-body {
        
        a {
            display: block;
            width: 150px;
            margin: 0 auto;
            color: #fff;
            background-color: #000;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 8px;
            text-decoration: none;
            text-align: center;
            margin-top: 30px
        } 
    }
}
.site-footer input {
    border-radius: 8px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-left: 20px;
    width: 70%;
    height: 40px;
}



.site-footer .btn {
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

label {
    display: inline-block;
    margin-bottom: 0;
}
.redbar {
    background-color: #D81838;
    color: #fff;
    margin: 0;
    min-height: 35px;
    line-height: 35px;
    text-align: center
}

.redbar .topic-block-title, .redbar h2 {
    display: none;
    visibility: hidden;
}

.redbar .twelve80 {
    padding: 10px 15px 1px;
}


@media only screen and (max-width:1280px) {} 

@media only screen and (max-width:1024px){} 

@media only screen and (max-width:400px) {}   