﻿///MAIN SEARCH

.negative-top {
    position: absolute;
    width: 100%;
    //keep, need beforemight need again
    // margin-top: -55px;
    z-index: 16
}

.main-search {
    margin: 0 auto;
}

.negative-clickfix {
    display: block;
    height: 210px
}

.main-search {
    //border: 1px #000 solid;
    z-index: 99;
    margin: -210px auto 50px;
    width: 800px;
    max-width: 100%;
    color: #fff;


    .search-tabs {
        // font-size: 0;
    }

    .search-tab {
    }

    .search-tab:last-of-type {
        //float: right;
        margin-right: 0;
    }

    .search-tab.active {
        background-color: $primaryhex;
        background-color: $grayhex;
    }

    .search-fields {
        // display: none;

        label {
            display: none;
        }
    }

    .search-fields.active {
        display: block;
    }

    .search-swap {
        clear: both;
        background-color: $primaryhex;
        background-color: $grayhex;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 30px 20px 50px;
        text-align: center;
        -webkit-box-shadow: 2px 2px 8px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 2px 2px 8px 0px rgba(50, 50, 50, 0.75);
        box-shadow: 2px 2px 8px 0px rgba(50, 50, 50, 0.75);

        h2 {
            color: #fff;
        }

        input {
            width: 60%;
            height: 39px;
            border-radius: 8px;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            padding-left: 20px;
            margin-right: 0;
        }

        .btn {
            border-radius: 8px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: -4px;
            margin-top: -3px;
            height: 39px;
            border: 0;
        }
    }


    ul {
        margin: 0;
        padding: 0;
    }

    li {
        width: 32.8%;
        display: inline-block;
        text-align: center;
        font-size: 0;

        a {
            display: block;
            background-color: $grayhex;
            background-color: $primaryhex;
            // background-color: #d1d1d1;
            text-align: center;
            padding: 15px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            white-space: normal;
            margin: 0;
            color: #fff;
            //   color: #000;
            font-size: 16px;
        }

        a.active {
            // color: #fff;
        }

        a:focus {
            outline: none;
        }

        a:hover {
            color: #fff;
            background-color: $accenthex;
            text-decoration: none;
        }
    }


    .item {
        min-height: 0;

        .vertical {
            display: none;
        }
    }

    .item-content {
        display: none;
    }

    .active {
        .item-content {
            display: block;
        }
    }
}
a.dropdown-item.feed-item { color: #000}
.main-swap {
    .sf-parts,
    .sf-buy {
        .btn {
            border-radius: 8px;
            height: 39px;
            border: 0;
            margin: 0 5px;
        }

        a.btn {
            color: #fff;
        }
    }
}

.main-search li {
    margin-right: 4px
}

.main-search li:last-of-type {
    float: right;
    margin-right: 0px
}

@media only screen and (max-width:1024px) {
}

@media only screen and (max-width:823px) {

    .main-search {
        //border: 1px #000 solid;
        width: 94%;
        z-index: 99;
        margin: 0px auto 50px;
    }

    .negative-top {
        position: relative;
        width: 100%;
    }

    .main-search {
        li {
            width: 100%;

            a {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                border-bottom: 1px #fff solid;
            }

            a:after {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                content: "\f078";
                padding-left: 10px;
            }

            a.active:after {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                content: "";
                padding-left: 10px;
            }
        }

        li:last-of-type {
            float: right;
        }
    }

    .negative-clickfix {
        display: none;
        height: 0;
    }
}

@media only screen and (max-width:600px) {
}

@media only screen and (max-width: 320px) {
}
