// TYPOGRAPHY
body {
    font-family: $roboto !important;
    font-size: 15px;
    //this overflow might come back to haunt me
    overflow-x: hidden;
}

a,
a:visited {
    color: $accenthex;
}

a:hover {
    color: $primaryhex;
}

.entry-content a {
    text-decoration: underline;
}

h1,
h2,
h3,
h4 {
    font-weight: normal;
    font-family: $archivo !important;
    line-height: 1.5em;
}


h1 {
    font-size: 4em;
    margin: 0;
}

h2 {
    font-size: 3em;
}

.black {
    font-weight: 900;
}

h3 {
    font-size: 2.4em;
}

h4 {
    font-size: 1.75em;
}

figcaption {
    text-align: center;
    font-size: .9em;
    color: #939f9e;
}

blockquote {
    font-size: 1.25em;
    margin: 0 3% 3% 3%;
    border-left: 8px #f1f1f1 solid;
    padding-left: 10px;
}


// DefaultClean styles adjsutments
.item-box .product-box-add-to-cart-button {
    border-radius: 8px
}

.item-box input[type=button] {
    border-radius: 8px
}


.search-box .search-box-button {
    border-radius: 8px
}


a.read-more {
    border-radius: 8px
}

input, select {
    border-radius: 8px !important
}

.buttons, .button-1, .button-2 {
    //hit buttons with hammer
    border-radius: 8px !important
}

// HEADER
.header-logo {
    text-align: center;
}

.search-box {
    display: inline-block;
}

.header-links {
    display: inline-block;
}

.header-lower {
    padding: 5px 0;
}

.header-upper {
    border-bottom: 0px solid #ddd;
}

.header-menu {
    border: 0;
    padding: 0;
    margin: 0 auto 0px
}

.header-menu > ul > li {
    border: 0;
    text-transform: uppercase;
}


//FOOTER
.footer {
    background-color: $grayhex;
    // border-top: 1px #fff solid;
    color: #fff;

    a {
        color: #fff;
    }

    .title {
        font-family: $archivo;
        color: #fff;
    }
}

.site-footer {
    padding: 60px 0;
    color: #fff;

    a {
        color: #fff;
    }

    .twelve80 {
    }

    i, em {
        font-size: 2.25em;
        padding-left: 10px;
        color: #fff;
        padding-top: 10px;
    }
}


// DIgital Downloads

.overview .download-sample-button {
    background-color: $primaryhex;
    color: #fff;
    background-image: none;
    padding: 12px 25px 12px 25px;
}

.overview .download-sample-button:hover {
    background-color: $accenthex;
    color: #fff;
    text-decoration: none;
}

//product-reviews-overview
//hideem

.product-reviews-overview {
    visibility: hidden;
    display: none;
}

.search-box {
    margin-top: 10px;
}

.search-box input.search-box-text {
    width: 200px;
}

.ui-autocomplete {
    width: 200px !important;
}

.search-box-button {
    margin-left: 0 !important;
    border-radius: 8px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    background-color: #000 !important;
    width: 80px;
}

.search-box-text {
    border-radius: 8px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-left: 20px;
    width: 200px;
}

.center-1 {
    margin-bottom: 0;
}


.new-parts {
    .picture, .rating {
        display: none;
    }

    .details {
        margin-left: 0 !important
    }
}

.site-footer h3 {
    font-size: 1em;
    padding-bottom: 15px;
    color: #fff;
}

.emergency .topic-block-title, .contact-cards .topic-block-title {
    display: none;
    visibility: none;
}

.emergency .topic-block-body {
    width: 800px;
    margin: 0 auto;
    max-width: 100%;
}

.contact-cards .topic-block-body {
    padding: 20px;
    border: 1px #ccc solid;
    min-height: 250px;
    border-radius: 8px
}

.contact-cards {
    h3 {
        font-size: 1.2em;
        text-align: left;
    }
}



@media only screen and (max-width:1000px) {
    .header-selectors-wrapper {
        padding: 0;
        border: 0;
    }

    .header-links-wrapper {
        position: relative;
        padding: 0;
    }

    .search-box {
        //display: none;
        display: block;
        width: 290px;
        margin: 0 auto;
        // text-align: center;
    }

    #topcartlink {
        display: inline;
    }

    .header-menu {
        width: 100%;
    }

    .header-links {
        text-align: center;
        width: 100%;
    }

    .menu-toggle {
        background-image: none;
        float: right;
        background-color: #fff;
        margin-top: 15px;
        margin-right: 15px;
        color: $grayhex;
        font-size: 1.5em;
    }

    .header {
        width: auto;
        text-align: left;
        float: left;
    }

    .top-menu.mobile {
        clear: both;
    }

    .top-menu > li {
        background-color: $primaryhex;
        color: #fff;

        a {
            color: #fff;
            padding: 15px;
            min-height: 0;
            font-size: 1.1em;
        }

        .sublist {
            background-color: $primaryhex;
            color: #fff;
            margin: 0;
            //  display: block;
        }

        .sublist-toggle {
            background-image: none;
            border: 0;
            margin: 0;
        }
    }

    .top-menu .sublist li {
        margin: 0;

        a {
            margin-left: 20px;
            padding: 10px;
        }
    }

    .top-menu .sublist {
        padding: 0;
    }

    .divider {
        clear: both;
        height: 1px;
        border-bottom: 1px #f1f1f1 solid;
    }

    .header-logo {
        margin: 0;
        padding: 0;
        padding: 10px;

        img {
            height: 60px;
        }
    }

    .sublist-toggle::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f054";
    }

    .show .sublist-toggle::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f078";
    }

    .top-menu.mobile {
        .show {
            background-color: $accenthex !important;

            li {
                background-color: $accenthex !important;
            }
        }
    }
}

.search-results {
    .picture {
        display: none;
        visibility: hidden;
    }

    .product-list .item-box .picture + .details {
        margin: 0 0 0 0px;
    }
}

@media only screen and (max-width:823px) {
}

@media only screen and (max-width:600px) {
}

@media only screen and (max-width: 320px) {
}

/* save for later
.page-body img {
    margin: 30px
}

*/