﻿/* BULLET NAVIGATION */



.theme-custom .nivo-controlNav {
    bottom: 10% !important;
}

.theme-custom .nivo-controlNav a {
    display: inline-block;
    width: 24px !important;
    height: 3px !important;
    margin: 0 7px;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.2);
    background-color: rgba(255,255,255,.5) !important;
    font-size: 0;
}

.theme-custom .nivo-controlNav a.active {
    background-color: #fff !important;
}

/* Caption styles */
.nivo-caption {
    position: absolute;
    left: 0px;
    bottom: 40% !important;
    left: 15% !important;
    right: 15% !important;
    background: none !important;
    color: #fff;
    width: 100%;
    z-index: 8;
    padding: 5px 10px;
    opacity: 1 !important;
    -moz-opacity: 1 !important;
    filter: alpha(opacity=1) !important;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    /* box-sizing: border-box; */ /* Opera/IE 8+ */
    font-size: 2.85em !important;
    font-family: "Archivo Black",sans-serif !important;
    line-height: .9em !important;
    text-shadow: 2px 0 14px #000;
}

.nivo-caption p {

}

@media only screen and (max-width:800px) {
    .nivo-caption {

        font-size: 1.75em !important;
 
    }
}
@media only screen and (max-width:600px) {
    .nivo-caption {
        font-size: 1.25em !important;
        left: 2% !important;
        right: 2% !important;
    }
}
