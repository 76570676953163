// UTILITY
.clear {
    width: 100%;
    display: block;
    clear: both;
}

//LAYOUT
.twelve80 {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
}

.nine60 {
    width: 960px;
    max-width: 100%;
    margin: 0 auto;
}

.six40 {
    width: 640px;
    max-width: 100%;
}

.seventy {
    width: 75%;
    display: inline-block;
    vertical-align: top;
}

.thirty {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    margin-left: 4%;
}

.fifty {
    width: 45%;
    display: inline-block;
    vertical-align: top;
    margin-left: 0%;
    margin-right: 4%;
    margin-top: 0px;
    //li { font-size: 1.2em;}
}

.stack {
    padding: 60px 0;
    min-height: 30vh;
}

.divider {
    box-shadow: 0 4px 6px -6px #222;
    height:15px;
    margin-bottom:20px;
}

.center {
    text-align: center !important;
}

.white {
    background-color: #fff;
}

.gray {
    background-color: $grayhex;
}

.f1 {
    background-color: $f1;
}

.full-width {
    display: block;
    width: 100%;
}

// MARGIN HELPERS
.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt40 {
    margin-top: 40px;
}

.mt60 {
    margin-top: 60px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb60 {
    margin-bottom: 60px;
}

.mr20 {
    margin-right: 20px;
}

.ml20 {
    margin-left: 20px;
}

// PADDING HELPERS
.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pt20 {
    padding-top: 20px;
}

.pb5 {
    padding-bottom: 5px;
}

.pb10 {
    padding-bottom: 10px;
}

.pb20 {
    padding-bottom: 20px;
}

.pt60 {
    padding-top: 60px;
}

.pb60 {
    padding-bottom: 60px;
}


.visually-hidden {
    display: none;
    visibility: hidden;
}




// MEDIA QUERIES
@media only screen and (max-width:823px) {

    .seventy,
    .thirty,
    .fifty {
        width: 100%;
        display: block;
        margin-left: 0;
        margin-right: 0;
    }
}


@media only screen and (max-width:600px) {
}


@media only screen and (max-width: 320px) {
}
